<template>
    <div>
        <CCard class="zw-page">
            <CCardBody>
                <zw-table ref="table"
                          tableName="combine_files"
                          columnsPrefix="combine_files.column."
                          :items="myProvider"
                          :fields="fields"
                          :filter="filter"
                          :selected="selected"
                          title="common.menu.sales.combine-files"
                          :actions-list="getActions()"
                >
                    <template #cell(filename)="row">
                        <a :href="$root.getDocumentUrl(row.item.filename)"
                           @click.prevent="$root.openDocument(row.item.filename)"
                        >{{ row.item.filename }}</a>
                    </template>
                    <template #cell(user_id)="row">
                        {{'ID: '+row.item.user_id}}
                        {{ row.item.user ? ' ('+row.item.user.email+')' : '' }}
                    </template>
                </zw-table>
            </CCardBody>
        </CCard>
    </div>
</template>

<script>
import {mapGetters} from 'vuex'
import commonTable from '@/bundles/erika_common_bundle/mixins/common-table'

export default {
    name: 'CombineFiles',
    mixins: [commonTable],
    data() {
        return {
            saveFilters: true,
            tableOptions: {
                sortBy: 'updated_at',
                sortDesc: true,
            },
        }
    },
    methods: {
        ...mapGetters('CommonTable', ['getCommonTable']),
        myProvider(ctx) {
            return this.commonProviderFetch(ctx, 'CommonTable/fetchTableData', 'getCommonTable');
        },
        ctxBaseParams() {
            return {
                'url': '/combine-files'
            }
        },
        getActions() {
            return []
        },
    },
}
</script>